import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import Content from '@/components/common/Content'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'

const Page = props => {
  const { markdownRemark } = props.data
  const { frontmatter, html, excerpt } = markdownRemark
  const post = {
    ...frontmatter,
    html,
    excerpt
  }
  return (
    <Root>
      <SEO title={post.title} description={post.excerpt} location={props.location} />
      <Head>
        <PageTitle>{post.title}</PageTitle>
      </Head>
      <Main>
        <Row><Content html={post.html}/></Row>
        <Row css={styles.Breadcrumb}><Breadcrumb title={post.title} path={post.path}/></Row>
        <Row css={styles.Contact}><ContactLead /></Row>
      </Main>
    </Root>
  )
}

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const styles = {
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Contact: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `
}

export default PageBase(Page)

export const PageTemplateQuery = graphql`
  query PageTemplateQuery($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(truncate: true)
      frontmatter {
        postType
        date
        path
        number
        title
      }
    }
  }
`
